import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { getBracket, getDocList } from "../../actions/editorAction";
import RichViewer from "../common/RichViewer";
import RichViewerEmpty from "../common/RichViewerEmpty";
import RichViewerLoading from "../common/RichViewerLoading";
import isEmpty from "../../validation/is-empty";
import isEqual from "../../validation/is-equal";
const batasPixel = 1172;
const batasScroll = 300;
class ViewerDashboard extends Component {
  //Init
  state = {
    openStatus: true,
    width: window.innerWidth,
    doc: {},
    link: "",
    link_params: "",
    first_doc: {},
    scrollPixelsY: window.scrollY,
    sidebarClassname: "sidebar scrollSidebar",
    openbtnClassname: "openbtn scrollOpenbtn",
    doc_id: "",
    bracket: "",
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    const link_params_next =
      nextProps &&
      nextProps.match &&
      nextProps.match.params &&
      nextProps.match.params.link
        ? nextProps.match.params.link
        : "";
    const doc_list_new = !isEmpty(nextProps.editor.doc_list)
      ? nextProps.editor.doc_list
      : [];
    const first_doc = doc_list_new[0];
    const doc_new = doc_list_new.filter(
      (doc) => doc.link === link_params_next
    )[0];
    const doc_state = prevState.doc;
    const link_params_state = prevState.link_params;
    if (
      doc_list_new.length > 0 &&
      link_params_next &&
      (link_params_next !== link_params_state || !isEqual(doc_new, doc_state))
    ) {
      return {
        first_doc: doc_new,
        doc: doc_new,
        link_params: link_params_next,
      };
    } else if (!link_params_next) {
      return {
        first_doc,
      };
    } else return null;
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.getBracket(
      this.props &&
        this.props.match &&
        this.props.match.params &&
        this.props.match.params.bracket_link
        ? this.props.match.params.bracket_link
        : "blog"
    );
    this.props.getDocList(
      this.props &&
        this.props.match &&
        this.props.match.params &&
        this.props.match.params.bracket_link
        ? this.props.match.params.bracket_link
        : "blog"
    );
    window.addEventListener("resize", this.handleWindowSizeChange);
    document.getElementById("mySidebar").style.left = "2vw";
    document.getElementById("buttonSlider").style.marginLeft =
      "calc((2vw + 300px - 20px)/2)";
    if (this.state.width < batasPixel) {
      this.closeNav();
    } else {
      this.openNav();
    }
    window.addEventListener("scroll", this.handleScroll);
  }

  //handleClick doc list link
  handleClickDoc = (doc, link) => {
    this.setState({ doc, link }, () => {
      window.scrollTo(0, 0);
      if (this.state.width < batasPixel) {
        this.closeNav();
      } else {
        this.openNav();
      }
    });
  };

  //SideBar action
  toggleSideBar = () => {
    this.setState(
      {
        openStatus: !this.state.openStatus,
      },
      () => {
        this.state.openStatus ? this.openNav() : this.closeNav();
      }
    );
  };
  openNav = () => {
    this.setState({ openStatus: true }, () => {
      document.getElementById("mySidebar").style.left = "2vw";
      document.getElementById("buttonSlider").style.marginLeft =
        "calc((2vw + 300px - 20px)/2)";
    });
  };
  closeNav = () => {
    this.setState({ openStatus: false }, () => {
      document.getElementById("mySidebar").style.left = "-300px";
      document.getElementById("buttonSlider").style.marginLeft = "2vw";
    });
  };
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth }, () => {
      if (this.state.width < batasPixel) {
        this.closeNav();
      } else {
        this.openNav();
      }
    });
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
    window.removeEventListener("scroll", this.handleScroll);
  }
  handleScroll = () => {
    if (window.scrollY > batasScroll) {
      this.setState({
        sidebarClassname: "sidebar stickySidebar",
        openbtnClassname: "openbtn stickyOpenbtn",
      });
    } else {
      this.setState({
        sidebarClassname: "sidebar scrollSidebar",
        openbtnClassname: "openbtn scrollOpenbtn",
      });
    }
  };
  render() {
    const {
      doc_list,
      bracket,
      loadingList,
      loadingContent,
    } = this.props.editor;
    //state
    const {
      openStatus,
      sidebarClassname,
      openbtnClassname,
      doc,
      first_doc,
    } = this.state;
    const doc_final = isEmpty(doc) ? first_doc : doc;

    let viewerContent;
    if (loadingList || loadingContent) {
      viewerContent = <RichViewerLoading link={this.props.match.params.link} />;
    } else if (doc_final && doc_final.link) {
      viewerContent = <RichViewer isFromIOT={false} doc={doc_final} />;
    } else {
      viewerContent = <RichViewerEmpty link={this.props.match.params.link} />;
    }
    const authContent = (
      <div style={{ marginBottom: "500px" }}>
        <div style={{ textAlign: "center" }}>
          <div className="buttonShadow">{bracket.name}</div>
        </div>
        {bracket.sub_brackets.map(({ _id, name, link }, idx) => {
          const list_on_bracket = doc_list.filter(
            (blog) => blog.sub_bracket === link
          );
          const list_on_bracket_render = (
            <div>
              {list_on_bracket.map((doc, idx) => {
                const { title, _id, link } = doc;
                const idButton =
                  doc_final && doc_final._id && doc_final._id === _id
                    ? "activated"
                    : "not-activated";
                return (
                  <Link
                    to={`/${
                      this.props &&
                      this.props.match &&
                      this.props.match.params &&
                      this.props.match.params.bracket_link
                        ? this.props.match.params.bracket_link
                        : "blog"
                    }/${link}`}
                    className="buttonTableOfContent docList"
                    id={idButton}
                    key={_id}
                    onClick={this.handleClickDoc.bind(this, doc, link)}
                  >
                    <div className="textTableOfContent">{title}</div>
                  </Link>
                );
              })}
            </div>
          );
          return (
            <div key={_id}>
              <div className="buttonTableOfContent parentTableOfContent">
                <div className="textTableOfContent">{name}</div>
              </div>
              {list_on_bracket_render}
            </div>
          );
        })}
      </div>
    );
    return (
      <div>
        <div id="mySidebar" className={sidebarClassname}>
          {authContent}
        </div>
        <div
          className={openbtnClassname}
          id="buttonSlider"
          onClick={this.toggleSideBar}
          style={{ zIndex: "2" }}
        >
          {openStatus ? "<" : ">"}
        </div>
        {viewerContent}
      </div>
    );
  }
}
ViewerDashboard.propTypes = {
  //Object
  editor: PropTypes.object.isRequired,
  //Function
  getBracket: PropTypes.func.isRequired,
  getDocList: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  editor: state.editor,
});
export default connect(mapStateToProps, {
  getBracket,
  getDocList,
})(ViewerDashboard);
