const initialState = {
  loadingProcess: false,
  loadingContent: false,
  loadingList: false,
  bracket_list: [],
  bracket: {
    sub_brackets: []
  },
  doc_list: [],
  doc: {},
  picture: {},
  pictures_array: [],
  picture_cycle: 0
};
export default function(state = initialState, action) {
  switch (action.type) {
    case "CREATE_BRACKET":
      return {
        ...state,
        bracket_list: [action.payload, ...state.bracket_list]
      };
    case "SET_BRACKET":
      return {
        ...state,
        bracket: action.payload
      };
    case "SET_PICTURE_ARRAY":
      return {
        ...state,
        pictures_array: action.payload
      };
    case "SET_PICTURE":
      return {
        ...state,
        picture: action.payload,
        picture_cycle: state.picture_cycle + 1
      };
    case "DELETE_BRACKET":
      return {
        ...state,
        bracket_list: state.bracket_list.filter(
          bracket => bracket._id !== action.payload
        )
      };
    case "CREATE_DOC":
      return {
        ...state,
        doc_list: [action.payload, ...state.doc_list]
      };
    case "DELETE_DOC":
      return {
        ...state,
        doc_list: state.doc_list.filter(doc => doc._id !== action.payload)
      };
    case "GET_BRACKET_LIST":
      return {
        ...state,
        bracket_list: action.payload
      };
    case "GET_DOC_LIST":
      return {
        ...state,
        doc_list: action.payload
      };
    case "GET_DOC":
      return {
        ...state,
        doc: action.payload
      };

    case "SET_LOADING_PROCESS":
      return {
        ...state,
        loadingProcess: true
      };
    case "SET_LOADING_CONTENT":
      return {
        ...state,
        loadingContent: true
      };
    case "SET_LOADING_LIST":
      return {
        ...state,
        loadingList: true
      };
    case "CLEAR_LOADING":
      return {
        ...state,
        loadingProcess: false,
        loadingContent: false,
        loadingList: false
      };
    default:
      return state;
  }
}
