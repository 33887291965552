import React, { Component } from "react";
import { Route, Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import ViewerDashboard from "./components/editor/ViewerDashboard";
import his from "./actions/history";
import "./App.css";
import "./Rich.css";
import "draft-js/dist/Draft.css";

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router history={his}>
          <div>
            <Route exact path="/" component={ViewerDashboard} />
            <Route exact path="/:bracket_link" component={ViewerDashboard} />
            <Route
              exact
              path="/:bracket_link/:link"
              component={ViewerDashboard}
            />
          </div>
        </Router>
      </Provider>
    );
  }
}
export default App;
