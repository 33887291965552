import axios from "axios";
import isEmpty from "../validation/is-empty";

// const SERVER_URL = "http://localhost:4000";
const SERVER_URL = "https://api.mapid.io";
// const SERVER_URL = "http://iotmapidserver.us-west-2.elasticbeanstalk.com";

/*GENERAL*/

export const setLoadingProcess = () => {
  return {
    type: "SET_LOADING_PROCESS",
  };
};

export const setLoadingContent = () => {
  return {
    type: "SET_LOADING_CONTENT",
  };
};

export const setLoadingList = () => {
  return {
    type: "SET_LOADING_LIST",
  };
};

export const clearLoading = () => {
  return {
    type: "CLEAR_LOADING",
  };
};

export const setPicture = (content) => (dispatch) => {
  dispatch({
    type: "SET_PICTURE",
    payload: content,
  });
};

/* ****************************Document on Bracket Start**************************** */
//1. GET BRACKETS BY BRACKET_LINK
export const getBracket = (bracket_link) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  dispatch(setLoadingContent());
  axios
    .get(SERVER_URL + `/blog/get_bracket/${bracket_link}`, config)
    .then((res) => {
      if (!res.data) {
        axios
          .get(SERVER_URL + `/blog/get_bracket/blog`, config)
          .then((res) => {
            dispatch(clearLoading());
            dispatch({
              type: "SET_BRACKET",
              payload: res.data,
            });
          })
          .catch((err) => {
            dispatch(clearLoading());
            dispatch({
              type: "SET_BRACKET",
              payload: {
                sub_brackets: [],
              },
            });
          });
      } else {
        dispatch(clearLoading());
        dispatch({
          type: "SET_BRACKET",
          payload: res.data,
        });
      }
    })
    .catch((err) => {
      dispatch(clearLoading());
      dispatch({
        type: "SET_BRACKET",
        payload: {
          sub_brackets: [],
        },
      });
    });
};

//2. GET DOC LIST BY BRACKET LINK (AUTHOR/MEMBERS)
export const getDocList = (bracket_link) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  dispatch(setLoadingList());
  axios
    .get(SERVER_URL + `/blog/get_list_docs_public/${bracket_link}`, config)
    .then((res) => {
      if (isEmpty(res.data) || !res.data) {
        axios
          .get(SERVER_URL + `/blog/get_list_docs_public/blog`, config)
          .then((res) => {
            dispatch(clearLoading());
            dispatch({
              type: "GET_DOC_LIST",
              payload: res.data,
            });
          })
          .catch((err) => {
            dispatch(clearLoading());
            dispatch({
              type: "GET_DOC_LIST",
              payload: [],
            });
          });
      } else {
        dispatch(clearLoading());
        dispatch({
          type: "GET_DOC_LIST",
          payload: res.data,
        });
      }
    })
    .catch((err) => {
      dispatch(clearLoading());
      dispatch({
        type: "GET_DOC_LIST",
        payload: [],
      });
    });
};

//3. GET DOC DETAIL BY DOC_LINK (AUTHOR/MEMBERS)
export const getDoc = (doc_link) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  dispatch(setLoadingContent());
  axios
    .get(SERVER_URL + `/blog/get_doc_by_link_public/${doc_link}`, config)
    .then((res) => {
      dispatch(clearLoading());
      dispatch({
        type: "GET_DOC",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoading());
      dispatch({
        type: "GET_DOC",
        payload: [],
      });
    });
};

//18. SET BRACKET OBJECT
export const setBracket = (bracket) => (dispatch) => {
  dispatch({
    type: "SET_BRACKET",
    payload: bracket,
  });
};

//19. SET DOC OBJECT
export const setDoc = (doc) => (dispatch) => {
  dispatch({
    type: "GET_DOC",
    payload: doc,
  });
};
/* ****************************Document on Bracket Finish**************************** */
