import React, { Component } from "react";
import { connect } from "react-redux";
import { EditorState, convertFromRaw } from "draft-js";
import Editor from "draft-js-plugins-editor";

import { mediaBlockRenderer } from "../common/plugins/mediaBlockRenderer";
import SpinnerSimpleLogo from "../common/SpinnerSimpleLogo";
class RichViewerLoading extends Component {
  state = {
    doc: {
      editorState: `{"blocks":[{"key":"5ji2c","text":"Preparing documents for you ...","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`,
    },
    editorState: EditorState.createEmpty(),
  };
  onChange = (editorState) => this.setState({ editorState });
  componentDidMount() {
    const { editorState } = this.state.doc;
    const contentState = convertFromRaw(JSON.parse(editorState));
    const editorStateFinal = EditorState.createWithContent(contentState);
    this.setState({ editorState: editorStateFinal });
  }
  render() {
    //state
    const { editorState } = this.state;
    let className = "RichEditor-editor";
    var contentStateForClassname = editorState.getCurrentContent();
    if (!contentStateForClassname.hasText()) {
      if (
        contentStateForClassname.getBlockMap().first().getType() !== "unstyled"
      ) {
        className += " RichEditor-hidePlaceholder";
      }
    }
    const itemContent = (
      <div className={className} onClick={this.focus}>
        <Editor
          blockRendererFn={mediaBlockRenderer}
          blockStyleFn={getBlockStyle}
          customStyleMap={styleMap}
          editorState={editorState}
          onChange={this.onChange}
          placeholder="Loading document..."
          ref="editor"
          spellCheck={false}
          readOnly={true}
        />
      </div>
    );
    const authorContent = (
      <div style={{ marginTop: "30px" }}>
        <SpinnerSimpleLogo
          width={80}
          unik="loading_item_list"
          marginTop="0px"
        />
      </div>
    );
    return (
      <div style={{ marginBottom: "200px" }}>
        <div className="titleWraper">
          <div className="dashboardSpecial">
            <div className="titleDocument">Preparing documents for you ...</div>
          </div>
        </div>
        <div className="documentContainer">
          {authorContent}
          {itemContent}
        </div>
        <style
          dangerouslySetInnerHTML={{
            __html: `
            body{padding-top: 0px;}
            `,
          }}
        />
      </div>
    );
  }
}

//Other start
// Custom overrides for "code" style.
const styleMap = {
  CODE: {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};
function getBlockStyle(block) {
  switch (block.getType()) {
    case "blockquote":
      return "RichEditor-blockquote";
    case "center":
      return "RichEditor-center";
    default:
      return null;
  }
}
RichViewerLoading.propTypes = {};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(RichViewerLoading);
